<template>
  <section>
    <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'">
      <h1 class="bold-header-text app-header mb-3">
        <span :title="tooltipText">Did You Know Emails </span>
      </h1>
      <p>
        A monthly newsletter to deepen understanding of individual ACHC standards, quality concepts,
        or other program-focused topics.
      </p>
      <template v-slot:myTooltipTemplate="{ props }">
        <a>
          <strong>{{ props.title }}</strong>
        </a>
      </template>
    </Tooltip>
    <div class="resource-options mt-3">
      <b-form-group label="Program">
        <multiselect
          v-model="programs"
          :options="groupedPrograms"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :placeholder="$t('dropdown.select-a-program')"
          label="programDescription"
          track-by="programID"
          group-values="options"
          group-label="label"
          :preselect-first="false"
          @input="submitSelections"
        >
          <template slot="option" slot-scope="props">
            <span
              v-if="props.option.$isLabel"
              class="option__title"
              :title="props.option.$groupLabel"
              >{{ props.option.$groupLabel }}</span
            >
            <span v-else class="option__title" :title="props.option.programDetailDescription">{{
              props.option.programDescription
            }}</span>
          </template>
        </multiselect>
      </b-form-group>
    </div>
    <achc-collapsible-card
      v-for="{ program = '', categories = [], visible = true } in userSelectedPrograms"
      :key="program"
      :card-visible="visible"
    >
      <template slot="header">
        <h2 class="resource-program-header">{{ program }}</h2>
      </template>
      <template slot="body">
        <div>
          <div
            v-for="{ year, resource } in categories[0].categoryResources"
            :key="year"
            class="resource-year-group"
          >
            <h3 class="resource-category-header">{{ year }}</h3>
            <div v-for="{ resourceID = null, title = '', url = '' } in resource" :key="resourceID">
              <a class="resource-link" target="_blank" :href="url" :title="title">
                {{ title }}
              </a>
            </div>
          </div>
        </div>
      </template>
    </achc-collapsible-card>
    <div v-if="emptyStateVisible" class="resources-empty-state">
      No resources available based on current selections
    </div>
  </section>
</template>
<style lang="scss" scoped></style>
<script>
import CollapsibleCard from "../../../components/Common/CollapsibleCard.vue";
import Multiselect from "vue-multiselect";
import ResourceMixin from "../../../mixins/ResourceMixin.vue";
import CompanyMixin from "../../../mixins/CompanyMixin.vue";
import ProgramMixin from "../../../mixins/ProgramMixin.vue";
import DirtyDataMixin from "../../../mixins/DirtyDataMixin.vue";
import { bus } from "../../../main";

import { Tooltip } from "@progress/kendo-vue-tooltip";
export default {
  inject: ["tabsWithTooltip"],
  components: {
    "achc-collapsible-card": CollapsibleCard,
    multiselect: Multiselect,
    Tooltip,
  },
  async created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });

    this.tooltipText =
      this.tabsWithTooltip.find(({ to }) => to === "DidYouKnowEmails")?.tooltip || ""; // injected value

    var currentCompany = JSON.parse(localStorage.getItem("currentCompany"));
    await this.getSelectedProgramsByCompany(currentCompany.companyID);
    this.programs = this.selectedProgramsByCompany;

    await this.getAllProgramsByType();
    bus.$on("dirtyModalSaveChanges", (to) => {
      this.saveProgramsOfInterest(to);
    });
    this.initDirtyDataWatcher("programs");

    if (this.programs.length) {
      await this.submitSelections();
    }
    bus.$emit("showFullpageOverlay", {
      showLayover: false,
    });
  },
  destroyed() {
    bus.$off("dirtyModalSaveChanges");
  },
  mixins: [ResourceMixin, CompanyMixin, ProgramMixin, DirtyDataMixin],
  data() {
    return {
      userSelectedPrograms: [],
      tooltipText: "",
      programs: [],
    };
  },
  computed: {
    emptyStateVisible() {
      return !this.userSelectedPrograms.length;
    },
  },
  methods: {
    async submitSelections() {
      //Moved from Resource mixin because making this as a common end point
      await this.getResources("8", this.programs)
        .then(() => {
          var programs = this.programs.map(function (item) {
            return item["programWebDescription"];
          });
          var programSet = new Set(programs);

          this.userSelectedPrograms = this.resources.filter((obj) => programSet.has(obj.program));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async saveProgramsOfInterest(to) {
      this.company = JSON.parse(localStorage.getItem("currentCompany"));
      await this.updateSelectedPrograms(this.company.companyID, this.programs, null);
      this.setPreserveMessageAfterPush(true);
      this.$router.push(to.fullPath);
    },
  },
};
</script>
