var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('Tooltip',{attrs:{"content":'myTooltipTemplate',"anchor-element":'target',"position":'right'},scopedSlots:_vm._u([{key:"myTooltipTemplate",fn:function(ref){
var props = ref.props;
return [_c('a',[_c('strong',[_vm._v(_vm._s(props.title))])])]}}])},[_c('h1',{staticClass:"bold-header-text app-header mb-3"},[_c('span',{attrs:{"title":_vm.tooltipText}},[_vm._v("Did You Know Emails ")])]),_c('p',[_vm._v(" A monthly newsletter to deepen understanding of individual ACHC standards, quality concepts, or other program-focused topics. ")])]),_c('div',{staticClass:"resource-options mt-3"},[_c('b-form-group',{attrs:{"label":"Program"}},[_c('multiselect',{attrs:{"options":_vm.groupedPrograms,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":_vm.$t('dropdown.select-a-program'),"label":"programDescription","track-by":"programID","group-values":"options","group-label":"label","preselect-first":false},on:{"input":_vm.submitSelections},scopedSlots:_vm._u([{key:"option",fn:function(props){return [(props.option.$isLabel)?_c('span',{staticClass:"option__title",attrs:{"title":props.option.$groupLabel}},[_vm._v(_vm._s(props.option.$groupLabel))]):_c('span',{staticClass:"option__title",attrs:{"title":props.option.programDetailDescription}},[_vm._v(_vm._s(props.option.programDescription))])]}}]),model:{value:(_vm.programs),callback:function ($$v) {_vm.programs=$$v},expression:"programs"}})],1)],1),_vm._l((_vm.userSelectedPrograms),function(ref){
var program = ref.program; if ( program === void 0 ) program = '';
var categories = ref.categories; if ( categories === void 0 ) categories = [];
var visible = ref.visible; if ( visible === void 0 ) visible = true;
return _c('achc-collapsible-card',{key:program,attrs:{"card-visible":visible}},[_c('template',{slot:"header"},[_c('h2',{staticClass:"resource-program-header"},[_vm._v(_vm._s(program))])]),_c('template',{slot:"body"},[_c('div',_vm._l((categories[0].categoryResources),function(ref){
var year = ref.year;
var resource = ref.resource;
return _c('div',{key:year,staticClass:"resource-year-group"},[_c('h3',{staticClass:"resource-category-header"},[_vm._v(_vm._s(year))]),_vm._l((resource),function(ref){
var resourceID = ref.resourceID; if ( resourceID === void 0 ) resourceID = null;
var title = ref.title; if ( title === void 0 ) title = '';
var url = ref.url; if ( url === void 0 ) url = '';
return _c('div',{key:resourceID},[_c('a',{staticClass:"resource-link",attrs:{"target":"_blank","href":url,"title":title}},[_vm._v(" "+_vm._s(title)+" ")])])})],2)}),0)])],2)}),(_vm.emptyStateVisible)?_c('div',{staticClass:"resources-empty-state"},[_vm._v(" No resources available based on current selections ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }